div.page-container {
}

h1.page-title {
  margin: 0;
  margin-top: 1rem;
  margin-left: 0.5rem;
  font-size: 1.5rem;
}

h3.page-subtitle {
  margin: 0;
  font-weight: normal;
  color: #979797;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}

main.page-content {
  border-top: 1px solid #cacaca;
  /* padding: 0.5rem;
  padding-top: 1rem; */
}

/* Mobile */
@media (max-width: 600px) {
  h1.page-title {
    margin: 0;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    font-size: 1.1rem;
  }

  h3.page-subtitle {
    font-size: 0.825rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
  }

  main.page-content {
    /* padding: 0.5rem;
    padding-top: 1rem; */
  }
}
